import React, { useContext } from "react";
import { TranslationContext } from '../../translation/translationContext';

const styles = {
  summaryContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingLeft: 16,
    paddingRight: 16
  },
  summaryTitle: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 17,
    marginBottom: 16,
  },
  summaryText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
    width: '90%',
    line: 1,
    marginBottom: 16,
  },
  readMoreLink: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 14,
    color: '#0075FF',
    textDecoration: 'underline',
    marginBottom: 16,
  },
  line: {
    width: '95%',
    height: 1,
    backgroundColor: 'black',
    marginBottom: 16,
  }
};

const Summary = ({ style, title, text, onOpen }) => {
  const { translate } = useContext(TranslationContext);
  return (
    <div style={{...styles.summaryContainer, ...style }}>
      <p style={styles.summaryTitle}>{title}</p>
      <p style={styles.summaryText}>{text}</p>
      <a style={styles.readMoreLink} onClick={onOpen}>{translate('readMore')}</a>
      <div style={styles.line} />
    </div>
)};

export default Summary;
  