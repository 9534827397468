import React, { useContext } from "react";
import { TranslationContext } from '../../translation/translationContext';

const styles = {
  summaryBox: {
    "box-shadow": '2px 2px 18px rgba(0, 0, 0, 0.25)',
    borderRadius: 2
  },
  summaryHeaderContainer: {
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: 'rgba(196, 196, 196, 0.22)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 16,
    paddingRight: 16,
  },
  summaryHeader: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 400,
    marginRight: 8
  },
  additions: {
    fontFamily: 'Roboto',
    fontSize: 17,
    fontWeight: 400,
    color: 'red'
  }
};

const SummaryBox = ({ style, title, additions, children, emptyText }) => {
  const { translate } = useContext(TranslationContext);
  return (
    <div style={{...styles.summaryBox, ...style}}>
        <div style={styles.summaryHeaderContainer}>
        <h3 style={styles.summaryHeader}>{title}</h3>
        {!!additions && <h3 style={styles.additions}>{`(${additions} ${translate('new')})`}</h3>}
        </div>
        {children}
    </div>
)};

export default SummaryBox;