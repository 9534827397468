import React, { useState, useContext } from 'react';
import { TranslationContext } from '../../translation/translationContext';
import ImageUploader from "react-images-upload";

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    image: {
        flex: 1
    },
    editImage: {
        flex: 1,
        border: 'none'
    },
    infoContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    info: {
        paddingLeft: 24,
        paddingRight: 16
    },
    infoKey: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: 12,
        marginBottom: 4
    },
    infoValue: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 12,
        marginBottom: 8
    },
    buttonContainer: {
        alignSelf: 'flex-end',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    link: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: 12,
        color: 'red',
        marginRight: 12,
        marginBottom: 12,
        textDecoration: 'underline',
        fontStyle: 'italic'
    },
    input: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 12,
        marginTop: 4,   
        marginBottom: 8,
        width: '100%',
        border: 'none',
        'border-bottom': '1px solid grey',
        backgroundColor: 'transparent',
        outline: 'none'
    }
};

const ProfileView = (props) => {
    const { image, name, title, companyName, email, phoneNumber, country, registered, onSave } = props;

    const { translate } = useContext(TranslationContext);

    const [isEditing, setIsEditing] = useState(false);
    const [updatedData, setUpdatedData] = useState({ name, title, companyName, email, phoneNumber, country }); // TODO: Image

    const updateField = (key, value) => {
        setUpdatedData(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const reset = () => setUpdatedData({ name, title, companyName, email, phoneNumber, country });

    const onEditCancel = () => {
        if (isEditing) reset();
        setIsEditing(prev => !prev)
    };

    const save = () => {
        onSave(updatedData);
        setIsEditing(false);
    };

    const [currentImage, setCurrentImage] = useState(image);

    const onDrop = picture => {
        setCurrentImage(picture);
    };

    // console.log('currentImage', currentImage);

    const registerDate = new Date(registered);
    const memberSince = registerDate.toLocaleDateString();
    const orderedInfo = [
        { saveKey: 'name', visibleKey: translate('name') },
        { saveKey: 'title', visibleKey: translate('title') },
        { saveKey: 'companyName', visibleKey: translate('companyName') },
        { saveKey: 'email', visibleKey: translate('email') },
        { saveKey: 'phoneNumber', visibleKey: translate('phone') },
        { saveKey: 'country', visibleKey: translate('country') },
        { value: memberSince, visibleKey: translate('memberSince') }
    ];

    return (
        <div style={styles.container}>
            {/* {isEditing && <ImageUploader
                style={styles.editImage}
                withIcon={true}
                onChange={onDrop}
                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                withPreview={true}
                label=""
                buttonText="Choose new profile image"
                maxFileSize={5242880}
            />} */}
            <img style={styles.image} src={currentImage} alt='profile' />
            <div style={styles.infoContainer}>
                {orderedInfo.map(({ saveKey, visibleKey, value }) => (
                    <div style={styles.info}>
                        <p style={styles.infoKey}>{visibleKey}</p>
                        {isEditing && !!saveKey ? 
                            <input
                                style={styles.input}
                                type="text"
                                value={value ?? updatedData[saveKey] ?? props[saveKey] ?? ''}
                                onChange={event => updateField(saveKey, event.target.value)}
                            /> :
                            <p style={styles.infoValue}>{value ?? updatedData[saveKey] ?? props[saveKey] ?? translate('not available')}</p>
                        }
                    </div>
                ))}
            </div>
            <div style={styles.buttonContainer}>
                {isEditing && <a style={styles.link} onClick={save}>Save</a>}
                <a style={styles.link} onClick={onEditCancel}>{isEditing ? translate('cancel') : translate('edit')}</a>
            </div>
        </div>
    );
};

export default ProfileView;