import React, { useState, useContext, useEffect } from "react";
import { navigate } from "gatsby"
import Screen from '../components/views/screen';
import Navbar from '../components/navbars/insideNavbar';
import { TranslationContext } from '../translation/translationContext';
import { NewsContext } from '../news/newsContext';
import { MessagesContext } from '../messages/messagesContext';
import { EventsContext } from '../events/eventsContext';
import { ProfileContext } from '../profile/profileContext';
import { insideNavbarHeight } from '../assets/dimensions';
import SoftButton from '../components/buttons/softButton';
import SummaryBox from '../components/views/summaryBox';
import Summary from '../components/views/summary';
import ProfileView from '../components/views/profileView';
import profileImageDefault from '../assets/images/profile.png';
import WelcomeDialog from '../components/dialogs/welcomeDialog';
import { TOOL_URL } from '../constants';
import { isBrowser } from '../utils/globalFunctions';

const styles = {
  container: {
    paddingTop: insideNavbarHeight + 24,
    paddingLeft: 60,
    paddingRight: 60,
  },
  toolButton: {
    marginBottom: 40,
    padding: 24,
    fontSize: 20
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '80%',
  },
  leftBoxes: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginRight: 32,
    flex: 3
  },
  rightBoxes: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flex: 4,
  },
  messages: {
    marginBottom: 32,
    flex: 1,
    width: '100%'
  },
  profile: {
    flex: 2,
    width: '100%'
  },
  events: {
    width: '100%'
  },
  news: {
    marginBottom: 32,
    width: '100%'
  }
};

const MemberPage = () => {
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(false);

  const { translate } = useContext(TranslationContext);
  const { news } = useContext(NewsContext);
  const { events } = useContext(EventsContext);
  const { inbox } = useContext(MessagesContext);
  const { profile, editProfile, profileImage, uploadProfileImage } = useContext(ProfileContext);

  const onAccessTool = () => {
    if (isBrowser()) {
      window.location.href = TOOL_URL;
    }
  };

  const onCloseDialog = () => {
    setShowWelcomeDialog(false);
  }

  useEffect(() => {
    if (profile && profile?.id && profile?.hasSeenWelcomeMessage !== '1') {
      setShowWelcomeDialog(true);
      editProfile({ hasSeenWelcomeMessage: '1' });
    }
  }, [profile]);

  return (
    <Screen style={styles.container}>
      <Navbar currentTab={'/member'} />
      <WelcomeDialog isOpen={showWelcomeDialog} close={onCloseDialog} />
      <SoftButton
        style={styles.toolButton}
        text={translate('accessTool')}
        onClick={onAccessTool}
      />
        <div style={styles.contentContainer}>
          <div style={styles.leftBoxes}>
            <SummaryBox
              style={styles.messages}
              title={translate('messages')}
              additions={inbox.length}>
                <Summary
                  title={inbox?.[0]?.subject ?? translate('noMessagesTitle')}
                  text={inbox?.[0]?.body ?? translate('noMessagesText')}
                  onOpen={() => navigate('/messages')}
                />
            </SummaryBox>
            <SummaryBox
              style={styles.profile}
              title={translate('myProfile')}>
                <ProfileView
                  image={profileImage ?? profileImageDefault}
                  name={profile?.name ?? ''}
                  title={profile?.title ?? ''}
                  companyName={profile?.companyName ?? ''}
                  email={profile?.email ?? ''}
                  phoneNumber={profile?.phoneNumber ?? ''}
                  registered={profile?.create_date ?? ''}
                  country={profile?.country ?? ''}
                  onSave={editProfile}
                />
            </SummaryBox>
          </div>
          <div style={styles.rightBoxes}>
            <SummaryBox
              style={styles.news}
              title={translate('featuredNews')}
              additions={news.length}>
                <Summary
                  title={news?.[0]?.title ?? translate('noNewsTitle')}
                  text={news?.[0]?.text ?? translate('noNewsText')}
                  onOpen={() => navigate('/news')}
                />
            </SummaryBox>
            <SummaryBox
              style={styles.events}
              title={translate('eventsTitle')}
              additions={events.length}>
                <Summary
                  title={events?.[0]?.title ?? translate('noEventsTitle')}
                  text={events?.[0]?.text ?? translate('noEventsText')}
                  onOpen={() => navigate('/events')}
                />
            </SummaryBox>
          </div>
        </div>
    </Screen>
  )
}

export default MemberPage;
