import React, { useContext } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { TranslationContext } from '../../translation/translationContext';
import SummaryBox from '../views/summaryBox';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    content: {
        paddingLeft: 60,
        paddingRight: 60,
        paddingTop: 60,
        paddingBottom: 60
    },
    title: {
        marginTop: 16,
        alignSelf: 'flex-start',
        fontWeight: 400,
        fontSize: 22,
        fontFamily: 'Roboto'
    },
    text: {
        fontFamily: 'Roboto',
        'line-height': '175%',  
        marginTop: 16,
        alignSelf: 'flex-start',
        fontWeight: 400,
        fontSize: 17,
        marginBottom: 32,
        'white-space': 'pre-wrap'
    },
    regards: {
        fontFamily: 'Roboto',
        marginTop: 16,
        alignSelf: 'flex-start',
        fontWeight: 400,
        fontSize: 17,
        marginBottom: 8,
        'white-space': 'pre-wrap'
    }
};

const WelcomeDialog = ({ isOpen, close }) => {
    const { translate } = useContext(TranslationContext);
    return (
        <Dialog open={isOpen} onBackdropClick={close} fullWidth={false} maxWidth="xl">
            <DialogContent style={styles.container}>
                <SummaryBox title={translate('introduction')}>
                    <div style={styles.content}>
                        <h2 style={styles.title}>{translate('welcomeTitle')}</h2>
                        <p style={styles.text}>{translate('welcomeText')}</p>
                        <p style={styles.regards}>{translate('welcomeRegards')}</p>
                    </div>
                </SummaryBox>
                
            </DialogContent>
        </Dialog>
    );
};

export default WelcomeDialog;